<template>
  <div :class="{ horizontal_cover: viewType === 'horizontal_cover'}">
    <div class="commonCardsModule" data-testid="card-module">
      <div class="commonCardsModule-content">
        <!-- SELO -->
        <div class="label" v-if="isBlocked || !isLiberated">
          <div class="label__svg">
            <keep-alive>
              <IconSax :name="isLiberated ? 'lock' : 'calendar-1'" color="var(--neutral-gray-900)" size="1rem" />
            </keep-alive>
          </div>
        </div>

        <!-- IMAGE -->
        <Cover :cover="data && data.cover ? data.cover : noCover" :viewMode="'module'" :viewType="viewType" />

        <!-- HOVER -->
        <div class="hover">
          <template v-if="!isLiberated">
            <Unavaliable type="module" @show="denyCourse(data)" />
          </template>
          <template v-else-if="isBlocked">
            <Blocked type="module" @show="denyCourse(data)" />
          </template>
          <template v-else>
            <Allowed type="module" @show="$emit('show')" />
          </template>
        </div>
      </div>
    </div>

    <!-- TITLE -->
    <Title :title="data.title" :allow="allow" />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Blocked from "@/components/common/cards/messages/Blocked.vue";
import Unavaliable from "@/components/common/cards/messages/Unavailable.vue";
import Allowed from "@/components/common/cards/messages/Allowed.vue";
import Title from "@/components/common/cards/title/Title.vue";
import Cover from "@/components/common/cards/cover/Cover.vue";

export default {
  computed: {
    isBlocked() {
      const course = this.data.course.has_access === false;
      const module = this.data.has_access === false;
      return module || course;
    },
    isLiberated() {
      return this.data.is_liberated === true && this.data.course.is_liberated === true;
    },
  },
  methods: {
    ...mapActions({
      actionFetchCourseAvailability: "home/actionFetchCourseAvailability",
      actionShowModalInfoCourse: "home/actionShowModalInfoCourse",
      actionShowModalBuyCourse: "home/actionShowModalBuyCourse",
    }),
    async denyCourse(item) {
    
      if (item.course.has_access  === false) {
        const response = await this.actionFetchCourseAvailability(item.course_id);

        const data = {
          id: response.id,
          title: response.title,
          cover: response.cover,
          button: response.sale_button_txt,
          description: response.sale_description,
          link: response.sale_link,
          video: response.sale_video,
          video_thumb: response.sale_video_thumb,
          video_type: response.sale_video_type,
        };

        this.actionShowModalBuyCourse({
          active: true,
          data,
        });
      } else if (item.course.has_access === true && item.course.is_liberated === false || item.is_liberated === false) {
        const data = {
          rule: item.course.rule_liberation || item.rule_liberation,
          date: item.course.liberated_at || item.liberated_at,
          type: 4,
        };

        this.actionShowModalInfoCourse({
          active: true,
          data,
        });
      }
    },
  },
  components: {
    Blocked,
    Unavaliable,
    Allowed,
    Cover,
    Title,
  },
  props: {
    allow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    noCover: {
      type: Object,
    },
    viewType:{
      type: String
    }
  },
};
</script>

<style lang="scss" scoped>

.horizontal_cover{
  width: 153px;
}
.commonCardsModule {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  &-title {
    margin-top: 8px;
    color: var(--fontcolor);
    @extend .body-semibold-14;

    &.blocked {
      color: var(--neutral-gray-500);
    }
  }

  &-content {
    position: relative;
    cursor: pointer;

    .blocked {
      opacity: 0.6;
    }
    .hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    img {
      width: 100%;
      height: auto;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &:hover {
    .commonCardsModule-content .hover {
      opacity: 1;
    }
  }

  .label {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    transition: opacity 0.3s ease;

    &__svg {
      position: relative;
      left: -7px;
      top: 7px;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 50%;

      ::v-deep .svgCss svg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 12px;
        height: 12px;
      }
    }
  }
}
</style>
